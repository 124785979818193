import React, { useState } from "react";
import { listObjects, uploadObject, deleteObject } from "./s3-service";
import "./App.css";

import AWS from "aws-sdk";
AWS.config.update({
  accessKeyId: "AKIAQFLZD2GU4CUIJIEI",
  secretAccessKey: "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",
  region: "us-east-1",
});

let schedule = [];

function App() {
  const [bucketName, setBucketName] = useState("informe-cripto-disparador");
  const [objects, setObjects] = useState([]);
  const [file, setFile] = useState(null);

  const handleListObjects = async () => {
    try {
      GetItems();
      const data = await listObjects(bucketName);
      setObjects(data);
    } catch (error) {
      console.error("Error listing objects: ", error);
    }
  };

    handleListObjects();

  const handleUploadObject = async () => {
    if (!file) return;
    try {
      await uploadObject(bucketName, file.name, file);
      let formDate = document.querySelector("#formDate");
      let formTime = document.querySelector("#formTime");
      let formText = document.querySelector('#formText');
      AddItem(
        ConvertDate(formDate.value),
        ConvertTime(formTime.value),
        file.name,
        formText.value
      );
      handleListObjects(); // Refresh the list after upload
    } catch (error) {
      console.error("Error uploading object: ", error);
    }
  };

  const handleDeleteObject = async (objectKey, index) => {
    try {
      await deleteObject(bucketName, objectKey);
      RemoveItem(schedule[index].split(",")[0]);
      handleListObjects(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting object: ", error);
    }
  };

  return (
    <div className="App">
      <h1>Disapro Agendado Informe Cripto</h1>
      <div className="container">
        <div className="form-group">
          <input type="file" onChange={(e) => setFile(e.target.files[0])} />
          <button onClick={() => window.location.reload()}>
            Carregar Novo Arquivo
          </button>
        </div>
        <div className="form-group">
          <label>
            Data: <input type="date" id="formDate"></input>
          </label>
        </div>
        <div className="form-group">
          <label>
            Hora: <input type="time" id="formTime"></input>
          </label>
        </div>
        <div className="form-group fullwidth">
          <label id="flexLabel">
            Texto: <textarea id="formText"></textarea>
          </label>
        </div>
        <div className="form-group">
          <button onClick={handleUploadObject}>Upload</button>
          <button onClick={handleListObjects}>Listar Objetos</button>
        </div>
      </div>
      <div className="object-list">
        <ul>
          {objects && objects.length > 0 ? (
            objects.map((obj, index) => (
              <li key={obj.Key}>
                {obj.Key + " " + schedule[index]}
                <button onClick={() => handleDeleteObject(obj.Key, index)}>
                  Deletar
                </button>
              </li>
            ))
          ) : (
            <li>Nenhum agendamento cadastrado.</li>
          )}
        </ul>
      </div>
    </div>
  );
}

function AddItem(date, time, file, text) {
  var ddb = new AWS.DynamoDB({ apiVersion: "2012-08-10" });

  var params = {
    TableName: "Informe_Disparador",
    Item: {
      informepkey: { S: Math.floor(Math.random() * 1000).toString() },
      date: { S: date },
      time: { S: time },
      file: { S: file },
      text: { S: text}
    },
  };

  // Call DynamoDB to add the item to the table
  ddb.putItem(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      console.log("Success", data);
    }
  });
}

function RemoveItem(key) {
  // Create the DynamoDB service object
  var ddb = new AWS.DynamoDB({ apiVersion: "2012-08-10" });

  var params = {
    TableName: "Informe_Disparador",
    Key: {
      informepkey: { S: key },
    },
  };

  // Call DynamoDB to delete the item from the table
  ddb.deleteItem(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      console.log("Success", data);
    }
  });
}

function GetItems() {
  // Create the DynamoDB service object
  var ddb = new AWS.DynamoDB({ apiVersion: "2012-08-10" });

  var params = {
    TableName: "Informe_Disparador",
  };

  // Call DynamoDB to scan the table and retrieve all items
  ddb.scan(params, function (err, data) {
    if (err) {
      console.log("Error", err);
    } else {
      for (let i = 0; i < data.Items.length; i++) {
        schedule[i] =
          data.Items[i].informepkey.S +
          "," +
          data.Items[i].date.S +
          "," +
          data.Items[i].time.S +
          "," +
          data.Items[i].file.S +
          "," +
          data.Items[i].text.S
      }
    }
  });
}

function ConvertDate(date) {
  let sdate = date.split("-");

  return `${sdate[2] + "/" + sdate[1] + "/" + sdate[0]}`;
}

function ConvertTime(time) {
  return `${time}:00`;
}

export default App;
