// aws-config.js
import { S3Client } from "@aws-sdk/client-s3";

const REGION = "us-east-1"; // e.g., "us-east-1"
const ACCESS_KEY_ID = "AKIAQFLZD2GU4CUIJIEI";
const SECRET_ACCESS_KEY = "EfKnqfBqvVSct6g945zbNFyYcvJd+Asi434cCUVF";

const s3Client = new S3Client({
  region: REGION,
  credentials: {
    accessKeyId: ACCESS_KEY_ID,
    secretAccessKey: SECRET_ACCESS_KEY,
  },
});

export { s3Client };