// s3-service.js
import { s3Client } from "./aws-config";
import {
  ListObjectsCommand,
  PutObjectCommand,
  DeleteObjectCommand,
} from "@aws-sdk/client-s3";

// List objects in S3 bucket
export const listObjects = async (bucketName) => {
  const command = new ListObjectsCommand({ Bucket: bucketName });
  try {
    const data = await s3Client.send(command);
    return data.Contents;
  } catch (err) {
    console.error("Error listing objects: ", err);
    throw err;
  }
};

// Upload object to S3 bucket
export const uploadObject = async (bucketName, objectKey, file) => {
  const command = new PutObjectCommand({
    Bucket: bucketName,
    Key: objectKey,
    Body: file,
  });
  try {
    const data = await s3Client.send(command);
    return data;
  } catch (err) {
    console.error("Error uploading object: ", err);
    throw err;
  }
};

// Delete object from S3 bucket
export const deleteObject = async (bucketName, objectKey) => {
  const command = new DeleteObjectCommand({
    Bucket: bucketName,
    Key: objectKey,
  });
  try {
    const data = await s3Client.send(command);
    return data;
  } catch (err) {
    console.error("Error deleting object: ", err);
    throw err;
  }
};
